import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import css from './Teams.module.css'
import client01 from './client01.jpeg'
import client03 from './antonio.jpeg'
import client02 from './alessandro.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl'
import { compose } from 'redux'
import Ambassadors from './Ambassadors'
import { ExternalLink } from '../index'
import config from '../../config'

const Teams = (props) => {
  const { rootClassName, className, intl } = props
  const classes = classNames(rootClassName || css.root, className)
  const { siteFacebookPage, siteInstagramPage, siteLinkdinHandle } = config

  const cilleDescription = intl.formatMessage({
    id: 'Teams.founding.cille'
  })

  const antonioDescription = intl.formatMessage({
    id: 'Teams.founding.antonio'
  })

  const clientRecord = [
    {
      image: client01,
      name: 'Cæcilie Fundal Månsson',
      position: 'CEO & Founder',
      linkedin: 'https://www.linkedin.com/in/caeciliefundalmaansson/',
      // email: 'cfm@skillpickr.com',
      // phone: '+4522462408',
      description: cilleDescription
    },
    {
      image: client02,
      name: 'Alessandro Merola',
      position: 'Chief Technology Officer',
      linkedin: 'https://www.linkedin.com/in/alex-merola-12732211b/',
      // email: 'cfm@skillpickr.com',
      // phone: '+4522462408',
      description: cilleDescription
    },
    {
      image: client03,
      name: 'Antonio Rosado',
      position: 'Business Coach & CO-Founder',
      linkedin: 'https://www.linkedin.com/in/antoniohanckerosado/',
      // email: '',
      // phone: '',
      description: antonioDescription
    }
  ]

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' })
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' })
  const goToLinkdin = intl.formatMessage({ id: 'Footer.goToLinkdin' })

  // prettier-ignore
  return (
    <div className={classes}>
      <section className="section">
        <div className="container" >
          <div className="row xs-justify-center">
            <div className="md">
              <div className="section-title text-center mb4 pb2">
                <h1 className="mb4">
                  <FormattedMessage id="Teams.founding.title" />
                </h1>
                <p className="text-muted para-desc mb0 mx-auto">
                  <FormattedMessage id="Teams.mission.text" />
                  {/* <FormattedMessage id="Teams.founding.text" values={{ breakline: <br /> }} /> */}
                </p>
                <div className={css.someLinks}>
                  <FormattedMessage id="SectionHero.followUs" />
                  <ExternalLink key="linkToInstagram" href={siteInstagramPage} className={css.icon} title={goToInsta} noIcon>
                    <span> Instagram, </span>
                  </ExternalLink>
                  <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb} noIcon>
                    <span> Facebook </span>
                  </ExternalLink>
                  <FormattedMessage id="SectionHero.or" />
                  <ExternalLink key="linkToFacebook" href="https://dk.linkedin.com/company/skillpickr" className={css.icon} title={goToLinkdin} noIcon>
                    <span> Linkdin </span>
                  </ExternalLink>
                  <FormattedMessage id="SectionHero.latestUpdate" />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            {clientRecord?.map(client => (
              <div
                className="sm mt3"
                key={client?.name}
              >
                <div className="card team team-primary text-center">
                  <div className="card-img team-image d-inline-block mx-auto rounded-pill shadow avatar avatar-ex-large overflow-hidden">
                    <img src={client?.image} className="img-fluid" alt="" />
                    <div className="card-overlay avatar avatar-ex-large rounded-pill"></div>

                    <ul className="list-unstyled team-social mb0">
                      <li className="list-inline-item">
                        <a href={client?.linkedin} className="btn btn-sm btn-pills btn-icon" target="_blank" rel="noreferrer">
                          <FontAwesomeIcon icon="fa-brands fa-linkedin" />                        </a>
                      </li>
                    </ul>
                    {/*end icon*/}
                  </div>

                  <div className="content mt3">
                    <p className="text-dark h6 mb0 title d-block">
                      {client?.name}
                    </p>
                    <small className="text-muted mb0 fw-normal">
                      {client?.position}
                    </small>
                    <p className="text-dark mb0 d-block">
                      {client?.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/*end row*/}
        </div >
        {/*end container*/}
        <br/>
        <Ambassadors />
      </section>
      {/*end section*/}
    </div >
  )
}

Teams.defaultProps = {
  rootClassName: null,
  className: null
}

const { string } = PropTypes

Teams.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired
}
export default compose(injectIntl)(Teams)
